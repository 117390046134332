import axios from 'axios';

/* * * STATE * * */
const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    subscribers: null,
    projectThreads:null,
    //Topic Forum
    //Threads
    topicThreads: null,
    newTopicThreadScheme: null,
    projectThreadPosts: null,
    threadScheme: null,
    newThreadDetails: null,
    postScheme: null,
    newPostParams: null,
    newPostResponse: null,
    deletePostResponse: null,
    postReplyMode: null,
    postEditMode: null,
    editPostScheme: null,
}

/* * * GETTERS * * */
const getters = {
    subscribers: state => state.subscribers,
    projectThreads: state => state.projectThreads,
    //Topic Forum
    //Threads
    topicThreads: state => state.topicThreads,
    newTopicThreadScheme: state => state.newTopicThreadScheme,
    projectThreadPosts: state => state.projectThreadPosts,
    threadScheme: state => state.threadScheme,
    newThreadDetails: state => state.newThreadDetails,
    postScheme: state => state.postScheme,
    newPostParams: state => state.newPostParams,
    newPostResponse: state => state.newPostResponse,
    editPostScheme: state => state.editPostScheme,
    deletePostResponse: state => state.deletePostResponse,
    postReplyMode: state => state.postReplyMode,
    postEditMode: state => state.postEditMode
}

/* * * MUTATIONS * * */
const mutations = {
    setSubscribers: (state, data) => state.subscribers = data,
    setProjectThreads: (state, projectThreads) => state.projectThreads = projectThreads,
    setNewThread: (state, newThread) => {

        if(state.projectThreads) {
            let projectThreadsCopy = state.projectThreads
            projectThreadsCopy.unshift(newThread)
            state.projectThreads = null
            state.projectThreads = [...projectThreadsCopy]
            state.newThreadDetails = newThread
        }

        if (state.topicThreads) {
            let topicThreadsCopy = state.topicThreads
            topicThreadsCopy.unshift(newThread)
            state.topicThreads = null
            state.topicThreads = [...topicThreadsCopy]
            state.newThreadDetails = newThread
        }


    },
    setUpdateThread: (state, updatedThread) => {
        if(state.projectThreads) {
            const newArray = state.projectThreads
            let threadObjectIndex = newArray.findIndex((thread => thread.id == updatedThread.id));
            newArray[threadObjectIndex].name = updatedThread.name
            state.projectThreads = null
            state.projectThreads = [...newArray]
        } else if (state.topicThreads) {
            const newArray = state.topicThreads
            let threadObjectIndex = newArray.findIndex((thread => thread.id == updatedThread.id));
            newArray[threadObjectIndex].name = updatedThread.name
            state.topicThreads = null
            state.topicThreads = [...newArray]
        }



    },
    setDeleteThread(state, id) {0
        if (state.projectThreads) {
            const projectThreadsCopy = state.projectThreads
            let filteredThreadsArray = projectThreadsCopy.filter((thread => thread.id !== id));
            state.projectThreads = null
            state.projectThreads = [...filteredThreadsArray]
        }

        if(state.topicThreads){
            const topicThreadsCopy = state.topicThreads
            let filteredThreadsArray = topicThreadsCopy.filter((thread => thread.id !== id));
            state.topicThreads = null
            state.topicThreads = [...filteredThreadsArray]
        }

    },
    //Topic Forum
    //Threads
    setTopicThreads: (state, data) => state.topicThreads = data,
    setNewTopicThreadScheme: (state, data) => state.newTopicThreadScheme = data,
    setProjectThreadPosts: (state, projectThreadPosts) => state.projectThreadPosts = projectThreadPosts,
    setThreadScheme:(state, data) => state.threadScheme = data,
    setPostScheme:(state, data) => state.postScheme = data,
    setNewPostParams: (state, params) => {
        let post = {
            ...state.newPostParams,
            ...params,
        }
        state.newPostParams = post
    },
    setNewPost: (state, post) => {
        let newObject = state.projectThreadPosts
        newObject.posts.push(post)
        state.projectThreadPosts = null
        state.projectThreadPosts = newObject
    },
    setEditPostScheme: (state, scheme) => state.editPostScheme = scheme,
    setDeletePostAttachments: (state, data) => {
        let newArray = state.editPostScheme
        let arrayIndex = newArray.findIndex((field => field.name === 'post_attachments'));
        newArray[arrayIndex].value = newArray[arrayIndex].value.filter((attachment => attachment.id !== data.id));
        state.editPostScheme = null
        state.editPostScheme = newArray
    },
    setUpdatedPost: (state, data) => {
        let newObject = state.projectThreadPosts
        let postObjectIndex = newObject.posts.findIndex((post => post.id ===  data.id));
        newObject.posts[postObjectIndex] = data
        state.projectThreadPosts = null
        state.projectThreadPosts = newObject
    },
    setDeletePost: (state, deletedPost) => {
        const newObject = state.projectThreadPosts
        newObject.posts = newObject.posts.filter((post => post.id !== deletedPost.id));
        state.projectThreadPosts = null
        state.projectThreadPosts = newObject
    },
    setNewPostResponse: (state, response) => state.newPostResponse = response,
    setDeletePostResponse: (state, response) => state.deletePostResponse = response,
    setPostReplyMode: (state, mode) => state.postReplyMode = mode,
    setPostEditMode: (state, mode) => state.postEditMode = mode
}

/* * * ACTIONS * * */
const actions = {

//Subscribers

    fetchSubscribers({commit}, id){
            axios.get(`/projects/${id}/subscribers_list.json`).then(res => {
                commit('setSubscribers', res.data);
            }).catch(error => {
                commit('setError', error.toString());
            })
    },

    async subscribe({commit}, {id, type}){

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
            };

            const res = await axios.post(`/projects/${id}/subscribe.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw commit('setAlertResponse', { type: 'error', text: res.data.message });

            commit('setAlertResponse', {type: 'successful', action: type, text: "Project Forum"});
            commit('setSubscribers', res.data);
            return Promise.resolve()

        } catch(error) {
            return Promise.reject(error)
        }

    },

// THREADS
    fetchProjectThreads({commit}, id) {
        axios.get(`/projects/${id}/pl_threads.json`).then(res => {
            commit('setProjectThreads', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchThreadScheme({commit},id){
        axios.get(`/projects/${id}/pl_threads/new.json`).then(res => {
            commit('setThreadScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createNewThread({commit}, {id, pl_thread}){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                project_id: id,
                pl_thread,
            };

            const res = await axios.post(`/pl_threads.json`, params, { headers: {'Content-Type': 'application/json'}})

            if(res.data.error) {
                commit('setAlertResponse', { type: 'error', text: res.data.message });
                return Promise.reject();
            }

            commit('setAlertResponse', {type: 'successful', action: 'created', text: "a new thread", pageType: 'newThread', data: res.data });
            commit('setNewThread', res.data);
            return Promise.resolve(res.data)

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }

    },

    fetchThreadEditScheme({commit}, id){
        axios.get(`/pl_threads/${id}/edit.json`).then(res => {
            commit('setThreadScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateThread({commit}, {thread_id, pl_thread, id}){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                id: thread_id,
                project_id: id,
                pl_thread,
            };

            const res = await axios.put(`/pl_threads/${thread_id}.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) {
                commit('setAlertResponse', { type: 'error', text: res.data.message });
                return Promise.reject();
            }
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: "thread"});
            commit('setUpdateThread', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

   async deleteThread({commit}, id) {
       try {
           const res = await   axios.delete(`/pl_threads/${id}.json`, {
               headers: {
                   'Content-Type': 'application/json',
                   'X-CSRF-Token': state.token,
                   id: id,
               }
           })

           if(res.data.error) {
              commit('setAlertResponse', { type: 'error', text: res.data.message });
               return Promise.reject()
           }
           commit('setAlertResponse', {type: 'successful', action: 'deleted', text: "thread"});
           commit('setDeleteThread', id);
           return Promise.resolve();

       } catch(error) {
           commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
           return Promise.reject(error)
       }
    },

//Topic THREADS
    fetchTopicThreads({commit}, id) {
        axios.get(`/topics/${id}/pl_threads.json`).then(res => {
            commit('setTopicThreads', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },


    fetchTopicThreadScheme({commit},id){
        axios.get(`/topics/${id}/pl_threads/new.json`).then(res => {
            commit('setThreadScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },


 //Project POSTS
    fetchProjectThreadPosts({commit}, thread_id) {
        axios.get(`/pl_threads/${thread_id}/posts.json`).then(res => {
            commit('setProjectThreadPosts', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async fetchPostScheme({commit},id){
        try {

            const res = await axios.get(`/pl_threads/${id}/posts/new.json`)

            if(res.data.error) throw res.data.message
            commit('setPostScheme', res.data);
            return Promise.resolve(res.data)

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error)
        }

/*       .then(res => {
         commit('setPostScheme', res.data);

        }).catch(error => {
            commit('setError', error.toString());
        })*/
    },

    fetchPostReplyScheme({commit},id){
        axios.get(`/posts/${id}/posts/new.json`).then(res => {
            commit('setPostScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },


    setNewPostParams({commit},params){
        commit('setNewPostParams', params);
    },

    async createNewPost({commit}, {post, postAction, postText}){
        try {

            const res = await axios.post(`/posts.json`, post, {headers: {'Content-Type': 'multipart/form-data'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: postAction, text: postText});
            commit('setNewPost', res.data);
            return Promise.resolve(res.data)

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: `${postAction}`, text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    setNewPostResponse({commit}, event){
        commit('setNewPostResponse', event);
    },

    async fetchEditPostScheme({commit}, id){
        try {
            const res = await axios.get(`/posts/${id}/edit.json`)

            if(res.data?.error) throw res.data.message
            commit('setEditPostScheme', res.data);
            return Promise.resolve(res.data)

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'update', text: error, timeout: 8000});
            return Promise.reject(error)
        }

    /*
        axios.get(`/posts/${id}/edit.json`).then(res => {
            commit('setEditPostScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })*/
    },

    //Delete Attachments
    async deletePostAttachments({commit}, id){
        try {
            const res = await axios.delete(`/attachments/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: '', text: 'deleted attachment'});
            commit('setDeletePostAttachments', res.data);
            return Promise.resolve()

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'update', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },


    async updateForumPost({commit}, {post_id, updatedPost}){
        try {
            const res = await axios.put(`/posts/${post_id}.json`, updatedPost, {
                headers: {'Content-Type': 'multipart/form-data'}
            })

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'post'});
            commit('setUpdatedPost', res.data);
            return Promise.resolve()

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'update', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async deletePost({commit}, id){
        try {
            const res = await axios.delete(`/posts/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'post'});
            commit('setDeletePost', res.data);
            return Promise.resolve()

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },



    setPostReplyMode({commit}, mode){
        commit('setPostReplyMode', mode);
    },

    setPostEditMode({commit}, mode) {
        commit('setPostEditMode', mode);
    }
}


export default {
    state,
    getters,
    mutations,
    actions,
}
